<template>
  <div ref="div" class="detail">
    <a-spin :spinning="loading">
      <Header title="卖品详情" @back="onBack(false)"></Header>
      <a-spin :spinning="loading">
        <a-form ref="form" :model="modelRef" scrollToFirstError name="form"
                :labelCol="{span: 4 }"
                :wrapperCol="{span: 18 }"
        >

          <h3 style="margin-left: 80px;" class="ui-title">基本信息</h3>

          <a-form-item label="卖品名称" name="mainTitle" :rules="[{ required: true, message: '必填项不允许为空' }]">
            <a-input :disabled="isSee" style="width: 400px;" v-model:value="modelRef.mainTitle" placeholder="请输入卖品名称"></a-input>
          </a-form-item>

          <a-form-item label="卖品分类" name="classifyId" :rules="[{ required: true, message: '必填项不允许为空' }]">
            <a-select :disabled="isSee" placeholder="请选择卖品分类" v-model:value="modelRef.classifyId" style="width: 180px;">
              <a-select-option v-for="item in classifyList" :vlaue="item.id" :key="item.id"> {{ item.title }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="图片">
            <a-upload
                :disabled="isSee"
                v-model:file-list="fileList"
                list-type="picture-card"
                action="/admin/ajaxUpload.do"
                accept="image/*"
                @change="handleFile"
            >
              <div v-if="fileList.length < 1">
                <Icon icon="PlusOutlined"></Icon>
                <div style="margin-top: 8px">Upload</div>
              </div>
            </a-upload>
          </a-form-item>

          <a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
            <a-input-number :min="0" :precision="0" :disabled="isSee" style="width: 400px;" v-model:value="modelRef.sort" placeholder="请输入优先级"></a-input-number>
          </a-form-item>

          <a-form-item label="卖品描述" name="detail">
            <a-input :disabled="isSee" style="width: 400px;" v-model:value="modelRef.detail" placeholder="请输入卖品描述"></a-input>
          </a-form-item>

          <a-form-item label="是否启用" name="isDisabled">
            <a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
          </a-form-item>

          <h3 style="margin-left: 80px;" class="ui-title">销售信息</h3>

          <a-form-item label="销售价" name="nowPrice" :rules="[{required: true, message: '必填项不允许为空'}]">
            <a-input-number :disabled="isSee" style="width: 150px;" v-model:value="modelRef.nowPrice" placeholder="请输入销售价"></a-input-number> 元
          </a-form-item>

          <a-form-item v-if="inventory" label="库存" name="stock">
            <a-input-number :disabled="isSee" style="width: 150px;" v-model:value="modelRef.stock" placeholder="请输入库存"></a-input-number>
          </a-form-item>

          <a-form-item label="基础销量" name="virtualSoldNum">
            <a-input :disabled="isSee" style="width: 400px;" v-model:value="modelRef.virtualSoldNum" placeholder="请输入基础销量"></a-input>
          </a-form-item>

          <h3 style="margin-left: 80px;" class="ui-title">套餐信息</h3>

          <a-form-item label="是否套餐" name="type">
            <a-switch :disabled="isSee || isEdit" v-model:checked="modelRef.type" checked-children="是" un-checked-children="否" />
            <div style="color: #c2c2c2; margin-top: 10px;">编辑不可修改；</div>
            <div style="color: #c2c2c2">套餐内单品须全部上架到影院后套餐才可上架至影院；</div>
            <div style="color: #c2c2c2">套餐的库存取决于影院上架的单品的最小库存；</div>
          </a-form-item>

          <div v-if="modelRef.type">
            <a-form-item label="套餐图片">
              <a-upload
                  :disabled="isSee"
                  v-model:file-list="packageList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  accept="image/*"
                  @change="handlePackage"
              >
                <div v-if="packageList.length < 1">
                  <Icon icon="PlusOutlined"></Icon>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </a-form-item>

            <h3 style="margin-left: 80px;" class="ui-title">固定卖品</h3>
            <a-form-item name="showRegular" label="是否含有固定卖品">
              <a-switch :disabled="isSee" v-model:checked="showRegular"></a-switch>
              <a-form-item-rest>
                <div v-show="showRegular">
                  <a-form-item style="margin-top: 20px;" name="onShowSales">
                    <a-form-item-rest>
                      <selectSalesSingle :disabled="isSee" v-model:value="goodsIdList" :selectItems="goodsList" @change="changeRegular($event)"></selectSalesSingle>
                    </a-form-item-rest>
                  </a-form-item>
                  <a-spin :spinning="loading" v-if="goodsList.length">
                    <div style="margin-top: 20px; width: 1000px;">
                      <a-table :pagination="false" :columns="regularColumns" :dataSource="goodsList" :rowKey="record => record.key"
                               :scroll="{ x: 450 }">
                        <template #bodyCell="{ column, record, index }">
                          <template v-if="column.key === 'imgUrl'">
                            <a-image :width="50" :src="record.imgUrl" />
                          </template>
                          <template v-if="column.key === 'num'">
                            <a-input-number :min="1" :precision="0" :disabled="isSee" v-model:value="record.num" placeholder="请输入数量" style="width: 150px;"></a-input-number>
                          </template>
                          <template v-if="column.key === 'action'">
                            <a-button :disabled="isSee" type="primary" @click="onDelete(record, index, -1)">移除</a-button>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </a-spin>
                </div>
              </a-form-item-rest>
            </a-form-item>

            <h3 style="margin-left: 80px;" class="ui-title">自选卖品</h3>
            <a-form-item name="showOptional" label="是否含有自选卖品">
              <a-switch :disabled="isSee" v-model:checked="showOptional"></a-switch>
              <a-form-item-rest>
                <div v-show="showOptional">
                  <a-form-item name="addOptional">
                    <a-button :disabled="isSee" style="margin-top: 20px;" type="primary" @click="onAddSet">新增自选</a-button>
                  </a-form-item>

                  <!--    循环自选卖品    -->
                  <div v-for="(item, index) in salesLists" :key="index" style="margin-bottom: 100px;">
                    <div style="display: flex">
                      <a-form-item label="自选名称">
                        <a-input :disabled="isSee" v-model:value="item.groupName" placeholder="请输入自选名称" style="width: 150px;"></a-input>
                      </a-form-item>
                      <a-form-item label="可选数量" style="margin-left: 20px;">
                        <a-input-number :min="1" :precision="0" :disabled="isSee" v-model:value="item.buyNum" placeholder="请输入数量" style="width: 150px;"></a-input-number>
                      </a-form-item>
                      <selectSalesSingle :disabled="isSee" style="margin-left: 20px;" v-model:value="item.goodsIdList" :selectItems="item.goodsList" @change="(dataList) => {item.goodsList = dataList}"></selectSalesSingle>
                      <a-button style="margin-left: 20px;" :disabled="isSee" @click="onDelSetting(index)" type="primary">删除该自选</a-button>
                    </div>
                    <a-spin :spinning="loading" v-if="item.goodsList.length">
                      <div style="margin-top: 20px; width: 1000px; margin-bottom: 20px;">
                        <a-table :pagination="false" :columns="optionalColumns" :dataSource="item.goodsList" rowKey="id"
                                 :scroll="{ x: 450 }">
                          <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'imgUrl'">
                              <a-image :width="50" :src="record.imgUrl" />
                            </template>
                            <template v-if="column.key === 'action'">
                              <a-button :disabled="isSee" type="primary" @click="onDelete(record, index)">移除</a-button>
                            </template>
                          </template>
                        </a-table>
                      </div>
                    </a-spin>
                  </div>

                </div>
              </a-form-item-rest>
            </a-form-item>
          </div>

          <a-form-item style="text-align: center;">
            <a-button v-if="!isSee" type="primary" html-type="submit" @click="onSubmit(true)">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-spin>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import selectSalesSingle from "@/components/select/selectSalesSingle/index.vue";
import { Icon } from '@/components/icon/icon.js';
import { getGoodsAllClassifyList } from '@/service/modules/mall.js';
import { getGoodsDetail } from "@/service/modules/goods";
import {getStoreGoodsDetail, saveGoods, updateGoods} from "../../../../service/modules/goods";
export default {
  name: "evaluateDetail",
  components: {
    Header,
    Icon,
    selectSalesSingle,
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isSee: {
      type: Boolean,
      default: false
    },
    inventory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showRegular: true,
      showOptional: true,
      type: false,
      goodsIdList: [],
      classifyList: [],
      showSpecifications: false,
      packageList: [],
      fileList: [],
      salesLists: [],
      loading: false,
      searchData: {},
      modelRef: {
        type: false,
        isDisabled: true,
      },
      columns: [{
        title: '优惠券标题',
        dataIndex: 'title',
        key: 'title',
        width: 100
      }, {
        title: '券类型',
        dataIndex: 'type',
        key: 'type',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 50
      }],
      list: [{
        key: '1',
        title: '券标题',
        type: '兑换券',
      }, {
        key: '2',
        title: '券标题',
        type: '代金券',
      }],
      regularColumns: [{
        title: '卖品名称',
        dataIndex: 'mainTitle',
        width: 100
      }, {
        title: '卖品图片',
        key: 'imgUrl',
        width: 100
      }, {
        title: '数量',
        key: 'num',
        width: 100
      }, {
        title: '分类',
        dataIndex: 'classifyName',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 100
      }],
      optionalColumns: [{
        title: '卖品名称',
        dataIndex: 'mainTitle',
        width: 100
      }, {
        title: '卖品图片',
        key: 'imgUrl',
        width: 100
      }, {
        title: '分类',
        dataIndex: 'classifyName',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 100
      }],
      goodsList: [],
    };
  },
  created() {
    this.getAllClassifyList();
    if(this.id && this.isEdit && !this.inventory) {
      this.getData();
    }
    if(this.inventory) {
      this.getStoreGoodsDetail();
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    async onSubmit(isBack) {
      this.$refs.form.validateFields().then(async () => {
        this.loading = true;
        // 套餐
        let packageGoodsGroupConfigList = [];
        if(this.goodsList.length) {
          let list = []
          this.goodsList.forEach(item => {
            list.push({
              generalGoodsId: item.id,
              num: item.num
            })
          })
          packageGoodsGroupConfigList.push({
            sort: 1,
            getType: 1,
            groupName: '固定卖品',
            goodsList: list
          })
        }
        if(this.salesLists.length) {
          this.salesLists.forEach(item => {
            if(item.goodsList.length) {
              let list = []
              item.goodsList.forEach(info => {
                list.push({
                  generalGoodsId: info.id
                })
              })
              packageGoodsGroupConfigList.push({
                sort: item.sort,
                getType: item.getType,
                groupName: item.groupName,
                buyNum: item.buyNum,
                goodsList: list
              });
            }
          })
        }

        this.modelRef.type = this.modelRef.type ? 5 : 1;
        let postData = JSON.parse(JSON.stringify(this.modelRef));
        postData.isDisabled = postData.isDisabled ? 0 : 1;
        postData.saleType = 2;
        try {
          let ret;
          if(this.isEdit) {
            postData.id = this.id;
            if(postData.type === 1) {
              ret = await updateGoods(postData)
            } else {
              ret = await updateGoods({
                ...postData,
                packageGoodsGroupConfigList: packageGoodsGroupConfigList
              });
            }
          } else {
            if(postData.type === 1) {
              ret = await saveGoods(postData)
            } else {
              ret = await saveGoods({
                ...postData,
                packageGoodsGroupConfigList: packageGoodsGroupConfigList
              });
            }
          }
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.onBack(isBack);
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getGoodsDetail({
          id: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          this.modelRef = ret.data.goods;
          delete this.modelRef.originPrice;
          this.packageGoodsGroupConfigList = ret.data.packageGoodsGroupConfigList;
          this.modelRef.isDisabled = this.modelRef.isDisabled ? false : true;
          if(this.modelRef.type === 1) this.modelRef.type = false;
          if(this.modelRef.type === 5) this.modelRef.type = true;
          this.type = this.modelRef.type;
        }
        if(ret.data.goods.imgUrl) {
          this.fileList = ret.data.goods.imgUrl.split(',').map(item => {
            return {
              url: item
            }
          })
        }
        if(ret.data.goods.bannerImgs) {
          this.packageList = ret.data.goods.bannerImgs.split(',').map(item => {
            return {
              url: item
            }
          })
        }
        if(ret.data.goods.classifyId) {
          let flag = true;
          this.classifyList.forEach(info => {
            if(info.id === ret.data.goods.classifyId) {
              flag = false;
            }
          })
          if(flag) {
            ret.data.goods.classifyId = null;
          }
        }
        if(ret.data.packageGoodsGroupConfigList) {
          ret.data.packageGoodsGroupConfigList.forEach(item => {
            if(item.getType === 1) {
              item.goodsList.forEach(info => {
                this.goodsIdList.push(info.generalGoodsId + '');
                this.goodsList.push({
                  id: info.generalGoodsId,
                  mainTitle: info.generalGoodsTitle,
                  imgUrl: info.generalGoodsImgUrl,
                  classifyName: info.generalGoodsClassifyName,
                  num: info.num
                });
              })
            } else {
              let list = [];
              let idList = [];
              item.goodsList.forEach(info => {
                idList.push(info.generalGoodsId + '');
                list.push({
                  id: info.generalGoodsId,
                  mainTitle: info.generalGoodsTitle,
                  imgUrl: info.generalGoodsImgUrl,
                  classifyName: info.generalGoodsClassifyName
                })
              })
              let goodsList = JSON.parse(JSON.stringify(list));
              this.salesLists.push({
                sort: item.sort,
                getType: item.getType,
                groupName: item.groupName,
                buyNum: item.buyNum,
                goodsIdList: idList,
                goodsList: goodsList
              })
            }
          })
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async getStoreGoodsDetail() {
      this.loading = true;
      try {
        let ret = await getStoreGoodsDetail({
          id: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          this.modelRef = ret.data.storeGoodsStock;
          this.packageGoodsGroupConfigList = ret.data.packageGoodsGroupConfigList
          this.modelRef.isDisabled = this.modelRef.isDisabled ? false : true;
          if(this.modelRef.type === 1) this.modelRef.type = false;
          if(this.modelRef.type === 5) this.modelRef.type = true;
          this.type = this.modelRef.type;
        }
        if(ret.data.storeGoodsStock.imgUrl) {
          this.fileList = ret.data.storeGoodsStock.imgUrl.split(',').map(item => {
            return {
              url: item
            }
          })
        }
        if(ret.data.storeGoodsStock.bannerImgs) {
          this.packageList = ret.data.storeGoodsStock.bannerImgs.split(',').map(item => {
            return {
              url: item
            }
          })
        }
        if(ret.data.storeGoodsStock.classifyId) {
          let flag = true;
          this.classifyList.forEach(info => {
            if(info.id === ret.data.storeGoodsStock.classifyId) {
              flag = false;
            }
          })
          if(flag) {
            ret.data.storeGoodsStock.classifyId = null;
          }
        }
        if(ret.data.packageGoodsGroupConfigList) {
          ret.data.packageGoodsGroupConfigList.forEach(item => {
            if(item.getType === 1) {
              item.goodsList.forEach(info => {
                this.goodsIdList.push(info.generalGoodsId + '');
                this.goodsList.push({
                  id: info.generalGoodsId,
                  mainTitle: info.generalGoodsTitle,
                  imgUrl: info.generalGoodsImgUrl,
                  classifyName: info.generalGoodsClassifyName,
                  num: info.num
                });
              })
            } else {
              let list = [];
              let idList = [];
              item.goodsList.forEach(info => {
                idList.push(info.generalGoodsId + '');
                list.push({
                  id: info.generalGoodsId,
                  mainTitle: info.generalGoodsTitle,
                  imgUrl: info.generalGoodsImgUrl,
                  classifyName: info.generalGoodsClassifyName
                })
              })
              let goodsList = JSON.parse(JSON.stringify(list));
              this.salesLists.push({
                sort: item.sort,
                getType: item.getType,
                groupName: item.groupName,
                buyNum: item.buyNum,
                goodsList: goodsList
              })
            }
          })
        }
      } catch(e) {
        this.loading = false;
      }
    },
    // 处理套餐信息的图片
    handlePackage(info) {
      if (info.file.status === 'done') {
        this.packageList = info.fileList;
        const $fileList = [...this.packageList];
        this.packageList = $fileList.map(file => {
          if (file.response) {
            this.modelRef.bannerImgs = file.response.data.imgUrl;
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    // 处理基本信息的图片
    handleFile(info) {
      if (info.file.status === 'done') {
        this.fileList = info.fileList;
        const $fileList = [...this.fileList];
        this.fileList = $fileList.map(file => {
          if (file.response) {
            this.modelRef.imgUrl = file.response.data.imgUrl;
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    async getAllClassifyList() {
      let ret = await getGoodsAllClassifyList({
        classifyType: 2
      });
      if (ret.code === 200) {
        let classifyList = ret.data.filter(item => {
          item.children = ret.data.filter(subItem => {
            return subItem.parentId === item.id;
          });
          return !item.parentId
        });
        this.classifyList = classifyList;
      }
    },
    onAddSet() {
      this.salesLists.push({
        sort: 1,
        getType: 2,
        groupName: '',
        buyNum: 1,
        goodsIdList: [],
        goodsList: [],
      });
    },
    onDelSetting(index) {
      this.salesLists.splice(index, 1);
    },
    // 移除卖品
    onDelete(info, index, flag) {
      if(flag === -1) {
        let i = this.goodsIdList.indexOf(info.id);
        this.goodsIdList.splice(i, 1);
        this.goodsList.splice(index, 1);
      } else {
        let i = this.salesLists[index].goodsIdList.indexOf(info.id);
        this.salesLists[index].goodsIdList.splice(i, 1);
        this.salesLists[index].goodsList = this.salesLists[index].goodsList.filter(item => item.id !== info.id);
      }
    },
    changeRegular(dataList) {
      this.goodsList = dataList.map(item => {
        return {
          num: 1,
          ...item
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.detail {
  .ui-title {
    position: relative;
    margin: 20px 0 10px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .ui-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 4px;
    height: 20px;
    border-radius: 2px;
    background-color: #1890ff;
    transform: translateY(-50%);
  }
}
.ui-form__item {
  margin-right: 30px;
}
</style>